<template>
  <div>
    <v-card class="graphs-container" :class="containerClass">
      <div class="d-flex flex-row justify-space-between">
        <div class="d-flex flex-row justify-space-between align-center">
          <v-icon class="icon-graphs-container-title">insights</v-icon>
          <span class="graphs-container-title" v-html="title"></span>
          <IconTooltip v-if="hasHelper" 
            icon="help"
            class="graphs-container-helper"
            iconClass="material-symbols-outlined"
            :tooltipText="helper">
          </IconTooltip>
        </div>
        <div class="expand-graphs-action d-flex flex-row align-center">
            <v-btn 
                :id="idExpandButton"
                class="btn-ag-link"
                :ripple="false"
                :disabled="disabled"
                @click="toggleExpandGraphs"
            >
                {{ expandText }} 
                <v-icon>{{ expandIcon }}</v-icon>
            </v-btn>
        </div>
      </div>
    </v-card>
    <transition name="slide-vertical" mode="out-in">
      <v-card v-show="showContainer" class="graphs-area">
        <slot name="graphs"> </slot>
      </v-card>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GraphsContainer',
  components: {
    IconTooltip: () => import('@/router/views/commons/IconTooltip.vue'),
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    helper: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isExpanded: true,
    };
  },
  computed: {
    expandIcon() {
      return this.isExpanded ? 'expand_less' : 'expand_more';
    },
    expandText() {
      return this.isExpanded ? 'Ocultar gráficos' : 'Exibir gráficos';
    },
    containerClass() {
      var containerClasses = '';
      if(this.disabled) {
        containerClasses = 'graphs-container-disabled';
      } else {
        containerClasses = this.isExpanded ? 'graphs-container-border-expanded' : '';
      }
      return containerClasses;
    },
    hasHelper() {
      return ![undefined, null].includes(this.helper);
    },
    pathForLocalStorage() {
      return 'graph_container_expanded_state_' + this.$route.path;
    },
    showContainer() {
      return this.isExpanded && !this.disabled;
    },
    idExpandButton() {
      return _.uniqueId('expandGraphButton_');
    }
  },

  created() {
    this.getStateFromLocalStorage();
  },

  methods: {
    toggleExpandGraphs() {
      if (!this.disabled) {
        this.isExpanded = !this.isExpanded;
        this.saveStateOnLocalStorage();
      }
    },
    saveStateOnLocalStorage() {
      localStorage.setItem(this.pathForLocalStorage, JSON.stringify(this.isExpanded));
    },
    getStateFromLocalStorage() {
        let graphExpandedState = localStorage.getItem(this.pathForLocalStorage);
        if (![undefined, null].includes(graphExpandedState)) {
          this.isExpanded = JSON.parse(graphExpandedState);
        }
    },
  },
};
</script>

<style scoped lang="scss">
@import './src/design/variables.scss';

.graphs-container {
  margin-top: $spacing-05;
  color: $brand-color-primary-medium;
  background-color: var(--v-lowMedium-base) !important;

  .icon-graphs-container-title {
    font-size: $font-size-sm;
    padding: 0 $spacing-03;
    color: $brand-color-primary-medium;
  }

  .graphs-container-title {
    font-size: $font-size-sm;
  }

  .graphs-container-helper {
    margin-left: 5px;
    cursor: help;
    font-size: $font-size-sm;
  }

  .expand-graphs-action {
    cursor: pointer;
    .v-btn {
      font-size: $font-size-xs;
      i {
        margin-left: $spacing-03;
        font-size: $font-size-xs;
      }
    }
  }

  &.graphs-container-disabled {
    color: $neutral-color-high-medium;
    background-color: $neutral-color-low-dark !important;
    .icon-graphs-container-title {
      color: $neutral-color-high-medium;
    }

    .expand-graphs-action {
      cursor: not-allowed;
    }
  }  
}

.graphs-container-border-expanded {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.graphs-area {
  border-top-width: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  background-color: var(--v-lowMedium-base) !important;
}

.v-tooltip_content {
  border-radius: $border-radius-sm;
  background-color: $neutral-color-high-medium;
}
.tooltip-basic {
  color: $neutral-color-low-light;
  font-size: $font-size-xxxs;
  font-weight: $font-weight-regular;
}
</style>
