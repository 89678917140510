var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "graphs-container", class: _vm.containerClass },
        [
          _c("div", { staticClass: "d-flex flex-row justify-space-between" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row justify-space-between align-center",
              },
              [
                _c("v-icon", { staticClass: "icon-graphs-container-title" }, [
                  _vm._v("insights"),
                ]),
                _c("span", {
                  staticClass: "graphs-container-title",
                  domProps: { innerHTML: _vm._s(_vm.title) },
                }),
                _vm.hasHelper
                  ? _c("IconTooltip", {
                      staticClass: "graphs-container-helper",
                      attrs: {
                        icon: "help",
                        iconClass: "material-symbols-outlined",
                        tooltipText: _vm.helper,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "expand-graphs-action d-flex flex-row align-center",
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-ag-link",
                    attrs: {
                      id: _vm.idExpandButton,
                      ripple: false,
                      disabled: _vm.disabled,
                    },
                    on: { click: _vm.toggleExpandGraphs },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.expandText) + " "),
                    _c("v-icon", [_vm._v(_vm._s(_vm.expandIcon))]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "transition",
        { attrs: { name: "slide-vertical", mode: "out-in" } },
        [
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showContainer,
                  expression: "showContainer",
                },
              ],
              staticClass: "graphs-area",
            },
            [_vm._t("graphs")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }